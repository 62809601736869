<template>
  <div>
    <v-card class="ma-3 pa-3">
      <v-card-title primary-title>
        <div class="headline primary--text">Edit Page</div>
      </v-card-title>
      <v-card-text>
        <template>
          <v-form
            v-model="valid"
            ref="createform"
            @submit.prevent="handleSubmit(save)"
            lazy-validation
          >
            <v-text-field
              label="Name"
              type="text"
              v-model="page.name"
              required
              readonly
            />
            <h3 class="mb-2">Select language from tabs</h3>
            <v-tabs
                v-model="tab"
                background-color="guacamole"
                dark
            >
                <v-tab v-for="item in page.data" :key="item.lang">
                {{ language(item.lang) }}
                </v-tab>
            </v-tabs>

            <v-md-editor v-model="text" :left-toolbar="ltoolbar" :right-toolbar="rtoolbar" height="400px" />

          </v-form>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="cancel" color="error"> Cancel </v-btn>
        <v-btn @click="submit" :disabled="!valid" color="guacamole">
          <span v-if="!loading">Save</span>
          <v-progress-circular v-else indeterminate color="biscuit" />
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      tab: 0,
      name: '',
      valid: false,
      ltoolbar: 'undo redo | h bold italic strikethrough quote | ul ol table hr | link',
      rtoolbar: 'preview sync-scroll fullscreen',
      items: [
        { tab: "GR", content: "this is the greek text" },
        { tab: "EN", content: "this is the english text" },
        { tab: "BG", content: "this is the bulgarian text" },
      ],
    };
  },
  created () {
    this.$store.dispatch('staticpages/getPage', this.$route.params.id)
  },
  methods: {
    language (code) {
        switch(code) {
            case 'en':
                return 'English - en'
            case 'al':
                return 'Albanian -al'
            case 'ba':
                return 'Bosnian - ba'
            case 'bg':
                return 'Bulgarian - bg'
            case 'hr':
                return 'Croatian - hr'
            case 'el':
                return 'Greek - el'
            case 'mk':
                return 'North Macedonian - mk'
            case 'rs':
                return 'Serbian - rs'
        }
    },
    cancel() {
        this.$router.back();
    },
    
    async submit() {
        this.$refs.createform.validate()
        let id = this.$route.params.id
        let payload = {}

        payload.name = this.name
        // payload.data = [
        //     {
        //         lang: 'el',
        //         text: this.items[0].content,
        //     },
        //     {
        //         lang: 'en',
        //         text: this.items[0].context,
        //     },
        //     {
        //         lang: 'bg',
        //         text: this.items[0].context
        //     }
        // ]
        payload.data = this.page.data
        
        try {
            this.$store.dispatch('staticpages/updatePage', {id: id, page: payload})
                .then(this.$router.push('/dashboard/pages/'))
        } catch (error) {
            console.log(error)
        }
    }
  },
  computed: {
    ...mapGetters({
        loading: 'staticpages/getLoading',
        page: 'staticpages/getPage'
    }),
    
    text: {
      get() {
        if (typeof this.page === 'undefined') {
            return ''
        }
        return this.page.data[this.tab].text;
      },
      set(text) {
        this.page.data[this.tab].text = text;
      },
    },
  },
};
</script>
